const config= (state = {}, action) => {

    switch(action.type){
        case 'CONFIGURATION': return action.config 
        default: return state
    }
}

export const getConfig = (state) => state

export default config